<template>
  <div class="main-page">
    <div class="container">
      <div class="d-md-none">
        <app-bar
          title="Kata Sandi Direset"
          previousLink="/"/>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6 d-flex align-items-center justify-content-center vh-md-100">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="mb-5 mt-5 mt-md-5">
              <img src="@/assets/icon/key.svg" alt="ikon kunci">
            </div>
            <span class="fs-24 fw-700 color-neutral-900 d-block">Hore Berhasil!</span>
            <span class="fs-14 fw-400 color-neutral-600 text-center my-3">Segera ganti password Anda di pengaturan akun.</span>
            <span class="fs-14 fw-400 color-neutral-600 text-center">
              Akan diarahkan otomatis dalam <span class="fw-700 color-neutral-900">{{ countDown }}</span> detik.
            </span>
            <span class="fs-14 fw-400 color-neutral-600 text-center">
              Atau <router-link :to="{name: 'Home'}" class="fs-14 fw-700 color-secondary-500">klik disini</router-link> jika belum diarahkan otomatis.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import AppBar from '@/components/AppBar.vue'

export default {
  components: {
    AppBar
  },
  setup () {
    const countDown = ref(10)
    const router = useRouter()
    const countDownTimer = () => {
      if (countDown.value > 0) {
        setTimeout(() => {
          countDown.value -= 1
          countDownTimer()
        }, 1000)
      } else {
        return router.push({ name: 'Home' })
      }
    }

    onMounted(() => {
      countDownTimer()
    })

    return {
      countDown
    }
  }
}
</script>
